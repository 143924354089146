<div class="date">{{date| date:'dd.MM.YYYY HH:mm:ss': 'GMT'}}</div>
<div [ngClass]="{'red':isImportant}" [innerHTML]="text"></div>

<hr *ngIf="isDisclosure"/>

<div *ngIf="isDisclosure">
  <small>
    <strong>
      Настоящее сообщение предоставлено непосредственно Субъектом раскрытия информации и опубликовано в соответствии с Положением о раскрытии информации эмитентами эмиссионных ценных бумаг, утвержденным Банком России 30.12.2014 №454-П,
      или
      Положением о требованиях к порядку и срокам раскрытия информации, связанной с деятельностью акционерных инвестиционных фондов и управляющих компаний паевых инвестиционных фондов, а также к содержанию раскрываемой информации,
      утвержденным Приказом ФСФР России от 22 июня 2005 г. №05-23/пз-н. За содержание сообщения и последствия его использования информационное агентство «ПРАЙМ» ответственности не несет.
    </strong>
  </small>
</div>
