import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { LocalStorageService } from '../Services/LocalStorageService/local-storage.service';

const authConfig: AuthConfig = {
  issuer: environment.auth.issuer,
  redirectUri: environment.auth.redirectUri,
  clientId: environment.auth.clientId,
  scope: environment.auth.scope,
  loginUrl: environment.auth.loginUrl,
  logoutUrl: environment.auth.logoutUrl,
  customQueryParams: {
    prompt: 'login'
  },
  showDebugInformation: true
}


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private oauthService: OAuthService, lss: LocalStorageService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.oauthService.hasValidIdToken()) {
      // @ts-ignore
      if(route.url.some(x=> x.toString().toLowerCase() == 'delete') && !this.oauthService.getIdentityClaims().role.some(x=> x == 'news_remover')){
        return false;
      }
      // @ts-ignore
      if(route.url.some(x=> x.toString().toLowerCase() == 'edit') && !this.oauthService.getIdentityClaims().role.some(x=> x == 'news_editor')){
        return false;
      }
      return true;
    } else {
      sessionStorage.setItem('returnUrl', state.url || '/');
      this.oauthService.initCodeFlow();
      return false;
    }
  }

}
