<footer class="footer">
  <div class="footer_container">
    <div class="footer_container_text">
      <p>
        &copy; 1996&ndash;{{year()}} <abbr title="Агентство экономической информации">АЭИ</abbr> ПРАЙМ. Все права защищены.<br/>
        Настоящий ресурс может содержать материалы 12+<br/>
        Свидетельство о регистрации средства массовой информации Эл №77-2402 от 21.02.2000<br/>
        <a href="/misc/agency/legal.asp">Правила цитирования и использования материалов</a><br/>
        Фотоматериалы предоставлены агентством &laquo;<a href="http://rian.ru" target="_blank">РИА Новости</a>&raquo;
      </p>
    </div>
    <div class="footer_container_text">
      <p>
        Служба информации: <a href="mailto:mail@1prime.ru">mail@1prime.ru</a><br/>
        Отдел маркетинга: <a href="mailto:market@1prime.ru">market@1prime.ru</a><br/>
        Отдел продаж: <a href="mailto:sales01@1prime.ru">sales01@1prime.ru</a><br/>
        <a href="tel:+74956453700">+7 (495) 645-37-00</a><br/>
        <a href="tel:88003335050">8-800-333-50-50</a> (для звонков из РФ бесплатно)<br/>
        Факс: <a href="tel:+74956374560">+7 (495) 637-45-60</a>
      </p>
    </div>
  </div>

</footer>
