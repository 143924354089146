import { AfterViewInit, Component, EventEmitter, forwardRef, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment/moment';
import { DaterangepickerComponent, DaterangepickerDirective } from 'ngx-daterangepicker-material';


export interface DateRange {
  startDate: Date,
  endDate: Date
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true
    }
  ]
})
export class CalendarComponent implements OnInit, ControlValueAccessor {

  public value: DateRange;
  private onChange = (value: any) => {
  };
  private onTouched = () => {
  };

  ranges: any = {
    'Сегодня': [moment(), moment()],
    'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
    // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'Текущий месяц': [moment().startOf('month'), moment().endOf('month')],
    'Предыдущий месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  }
  locale: any = {
    format: 'MM/DD/YYYY', // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
    displayFormat: 'DD.MM.YYYY', // default is format value
    direction: 'ltr', // could be rtl
    weekLabel: 'W',
    separator: ' - ', // default is ' - '
    cancelLabel: 'Отмена', // detault is 'Cancel'
    applyLabel: 'Применить', // detault is 'Apply'
    clearLabel: 'Очистить', // detault is 'Clear'
    customRangeLabel: 'Выбрать период',
    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    firstDay: 1 // first day is monday
  }

  visible: boolean = false;
  label: string;

  constructor() {
  }

  ngOnInit() {
    this.label = `${moment(new Date()).format('DD.MM.YYYY')} - ${moment(new Date()).format('DD.MM.YYYY')}`;
    this.writeValue({ startDate: new Date(), endDate: new Date() });
  }

  toggle = () => this.visible = !this.visible;

  choosedDate = (e) => {
    if (e.endDate.$d == 'Invalid Date') {
      e.endDate = e.startDate;
    }
    this.label = `${moment(new Date(e.startDate)).format('DD.MM.YYYY')} - ${moment(new Date(e.endDate)).format('DD.MM.YYYY')}`;
    this.toggle();
    this.writeValue({ startDate: e.startDate, endDate: e.endDate });
  }

  registerOnChange = (fn: any): void => this.onChange = fn;

  registerOnTouched = (fn: any): void => this.onTouched = fn;

  writeValue(obj: DateRange): void {
    this.value = obj;
    this.onChange(obj);
    this.onTouched();
  }
}
