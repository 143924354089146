<div class="filter">
  <div class="filter_calendar">
    <app-calendar [formControl]="calendar"></app-calendar>
  </div>
  <div class="filter_string">
    <div class="filter_string_cb">
      <input class="filter_string_cb_checkbox" type="checkbox" [(ngModel)]="isFulltext"> <span class="filter_string_cb_text"> Расширенный синтаксис поиска </span>
      <mat-icon class="filter_string_cb_icon" aria-hidden="false" aria-label="Example home icon">help</mat-icon>
    </div>
    <div class="filter_string_search">
      <input class="filter_string_search_input" type="text" placeholder="Найти" [(ngModel)]="searchString">
      <button class="filter_string_search_button" (click)="search()" mat-raised-button>Найти</button>
    </div>
  </div>
</div>
