import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateRange } from '../calendar/calendar.component';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface BaseFilterData {
  startDate: Date,
  endDate: Date,
  searchString: string,
  isFulltext: boolean
}

@Component({
  selector: 'app-base-filter',
  templateUrl: './base-filter.component.html',
  styleUrls: ['./base-filter.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseFilterComponent),
      multi: true
    }
  ]
})

export class BaseFilterComponent implements OnInit, ControlValueAccessor {

  calendar: FormControl;
  public value: BaseFilterData;
  private onChange = (value: any) => {
  };
  private onTouched = () => {
  };

  startDate: Date = new Date();
  endDate: Date = new Date();
  searchString: string = '';
  isFulltext: boolean = false;

  constructor(public dialog: MatDialog) {
  }

  ngOnInit() {
    this.calendar = new FormControl();
    this.calendar.valueChanges.subscribe(x => {
      this.startDate = x.startDate;
      this.endDate = x.endDate;
      this.search();
    });
  }

  search = () => this.writeValue({ startDate: this.startDate, endDate: this.endDate, searchString: this.searchString, isFulltext: this.isFulltext });

  registerOnChange = (fn: any): void => this.onChange = fn;

  registerOnTouched = (fn: any): void => this.onTouched = fn;

  writeValue(obj: BaseFilterData): void {
    this.value = obj;
    this.onChange(obj);
    this.onTouched();
  }
}
