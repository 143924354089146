<div class="logout">
  <div class="logout_content">

    <h1>Что такое Бизнес-лента?</h1>

    <div class="logout_content_block1">
      Бизнес-лента — это полная лента экономических новостей, подготовленная журналистами ПРАЙМ и доступная только подписчикам, источник достоверной, оперативной и объективной информации обо всех основных событиях, определяющих
      экономическую и
      политическую жизнь страны.
    </div>

    <div class="logout_content_auth">
      <a class="logout_content_auth_button" href="/">Авторизоваться</a>
      <div class="logout_content_auth_text">
        Если вы забыли пароль или еще не подписаны на продукты ПРАЙМ, и у вас нет логина, обратитесь в <a href="mailto:sales01@1prime.ru">отдел продаж</a>.
      </div>
    </div>

    <div class="logout_content_block2">
      <div class="logout_content_block2_info">
        <h3>Зачем платить, если есть свободный доступ?</h3>
        Оформив подписку, вы получаете <b>в пять раз больше новостей</b>, чем доступно остальным посетителям сайта в свободном доступе — <a href="http://1prime.ru/">горячей линии</a>.
      </div>

      <div class="logout_content_block2_phones">
        <div class="logout_content_block2_phones_text">
          Для оформления подписки, получения бесплатного тестового доступа или информации заполните заявку на нашем сайте или обратитесь в отдел продаж ПРАЙМ:
        </div>
        <div>
          <a href="tel:+74956453700">+7 (495) 645-37-00</a>
        </div>
        <div>
          <a href="tel:+74959747664">+7 (495) 974-76-64</a>
        </div>
        <div class="logout_content_block2_phones_mail">
          <a href="mailto:sales01@1prime.ru">sales01@1prime.ru</a>
        </div>
      </div>

    </div>
  </div>
