import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, combineLatestWith, debounceTime, delay, forkJoin, map, retryWhen, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { FilterService, FilterSettings } from '../../Services/FilterService/filter.service';
import { NewsFilter, NewsTitle } from '../main-news/main-news.component';
import { ApiService } from '../../Services/Api/api.service';
import { LocalStorageService } from '../../Services/LocalStorageService/local-storage.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.less']
})
export class TabComponent implements OnInit, OnDestroy {

  filterSettings: FilterSettings = {categories:[[],[],[],[]], domain:''};
  newsList: NewsTitle[] = [];
  loading: boolean = false;
  filter: NewsFilter = {bottomNewsId:0, domain:'', startDate:new Date(), topNewsId:0, endDate:new Date(), isFullTextSearch:false, searchString:'', categories:[]};
  newsCount: number;

  baseFilter: FormControl;
  catFilter: FormControl;

  protected unsubscribe$: Subject<any> = new Subject();

  constructor(private route: ActivatedRoute,
              private fs: FilterService,
              private api: ApiService,
              private lss: LocalStorageService) {
  }

  ngOnInit(): void {
    this.baseFilter = new FormControl();
    this.catFilter = new FormControl();
    this.loading = true;
    // при изменении фильтра перерисовываем весь массив новостей
    combineLatest([
      this.baseFilter.valueChanges,
      this.catFilter.valueChanges,
    ]).pipe(
      map(x => {
        this.loading = true;
        this.filter.categories = x[1];
        this.filter.startDate = x[0].startDate;
        this.filter.endDate = x[0].endDate;
        this.filter.searchString = x[0].searchString;
        this.filter.isFullTextSearch = x[0].isFullTextSearch;
        this.filter.topNewsId = null;
        this.filter.bottomNewsId = null;
        return this.filter;
      }),
      switchMap(x => forkJoin([
        this.api.getNewsList(x),
        this.api.getNewsCount(x)
      ])),
      retryWhen(errors => errors.pipe(tap(x => console.error(x)), delay(1000))),
      takeUntil(this.unsubscribe$)
    ).subscribe(x => {
      this.newsCount = x[1].newsCount;
      this.newsList = this.mapTitles(x[0], 0);
      this.loading = false;
    });

    this.route.url.pipe(
      map(segments => segments.join(''))
    ).subscribe(kind => {
      this.filterSettings = this.fs.getFilterSettings(kind);
      this.filter = {
        categories: [],
        domain: this.filterSettings.domain,
        endDate: new Date(),
        isFullTextSearch: false,
        searchString: '',
        startDate: new Date(),
        topNewsId: null,
        bottomNewsId: null
      };
      this.baseFilter.setValue(this.filter);
      this.catFilter.setValue(this.lss.getCategories(this.filterSettings.domain));
    });

  }

  pushNews = (n: NewsTitle) => {
    this.lss.addPushed(n.id);
    if (!this.newsList.some(news => news.id == n.id)
      && this.filter.categories.some(fCat => n.categories.some(nCat => nCat == fCat))
      && this.filter.domain == n.domain
    ) {
      if (!this.filter.searchString) {
        this.newsList[0].showDate = this.newsList[0].date.getDate() != n.date.getDate();
        this.newsList = [n, ...this.newsList];
        this.newsCount++;
      } else {
        this.refreshWithTop();
      }
    }
  }

  reconnect = (connected: boolean) => {
    console.log('Connected: ' + connected);
    if (connected && this.newsList.length > 0) {
      this.refreshWithTop();
    }
  };

  refreshWithTop = () => {
    this.loading = true;
    this.api.getNewsList({
      categories: this.filter.categories,
      searchString: this.filter.searchString,
      startDate: this.filter.startDate,
      domain: this.filter.domain,
      endDate: this.filter.endDate,
      isFullTextSearch: this.filter.isFullTextSearch,
      bottomNewsId: this.newsList[0].id,
      topNewsId: null
    }).subscribe(newPage => {
        console.log('refreshed');
        this.loading = false;
        if (newPage.filter(x => this.newsList.some(y => y.id != x.id)).length > 0) {
          newPage.filter(x => !this.newsList.some(y => y.id == x.id)).forEach(x => this.lss.addPushed(x.id));
          let res = this.mapTitles(newPage.filter(x => !this.newsList.some(y => y.id == x.id)), 0);
          if (res.length > 0) {
            this.newsList[0].showDate = false;
            this.newsList = res.concat(this.newsList);
            this.newsCount += res.length;

          }
        }
      }
    );
  }

  nextPage = (n: number) =>
    this.api.getNewsList({
      categories: this.filter.categories,
      searchString: this.filter.searchString,
      startDate: this.filter.startDate,
      domain: this.filter.domain,
      endDate: this.filter.endDate,
      isFullTextSearch: this.filter.isFullTextSearch,
      topNewsId: n,
      bottomNewsId: null
    }).subscribe(newPage => {
        let prevDay = this.newsList[this.newsList.length - 1].date.getDate();
        this.newsList = this.newsList.concat(this.mapTitles(newPage, prevDay));
      }
    );

  private mapTitles = (list: NewsTitle[], prevDay: number): NewsTitle[] => {
    let viewed = this.lss.getViewed();
    let pushed = this.lss.getPushed();
    return list.map((x, i, array) => {
      if (i > 0) {
        prevDay = moment(array[i - 1].date.toString().replace('Z', '')).toDate().getDate();
      }
      let date = moment(x.date.toString().replace('Z', '')).toDate();
      let news: NewsTitle = {
        id: x.id,
        viewed: viewed.some(id => id == x.id),
        title: x.title,
        priority: x.priority,
        date: date,
        pushed: pushed.some(id => id == x.id),
        showDate: date.getDate() != prevDay,
        categories: [],
        domain: x.domain ? x.domain : this.filter.domain
      }
      return news;
    });
  };

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  anyCategory = (): boolean => this.filter.categories?.length > 0;

}
