<div class="delete">
  <app-progress [loading]="loading"></app-progress>
  <div class="delete_header"> Удаление новости</div>
  <hr/>
  <div class="delete_body">
    <app-news-text *ngIf="news.body" [isImportant]="isImportant()" [date]="news.date" [text]="news.body" [isDisclosure]="news.isDisclosure"></app-news-text>
  </div>
  <div class="delete_buttons">
    <div class="delete_buttons_button">
      <button mat-button color="Basic" (click)="goBack()">Отмена</button>
    </div>
    <div class="delete_buttons_button">
      <button mat-button color="Basic" (click)="deleteNews()">Удалить</button>
    </div>
  </div>
  <app-not-found-text [show]="!loading && !news.body"></app-not-found-text>
</div>
