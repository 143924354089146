import { Injectable } from '@angular/core';
import { Category } from '../../Components/category-filter/category-filter.component';
import { OAuthService } from 'angular-oauth2-oidc';

export interface FilterSettings {
  categories: [Category[], Category[], Category[], Category[]];
  domain: string;
}


@Injectable({
  providedIn: 'root'
})

export class FilterService {

  constructor(private oauthService: OAuthService) {
  }

  isCategoryEnabled(domain:string, categoryName:string):boolean{
    // @ts-ignore
    let claims = this.oauthService.getIdentityClaims()?.permission;
    if(!claims){
      return false;
    }
    switch (domain) {
      case 'business':
        if(claims.some(x=> x == "bl_all")){
          return true;
        }
        break;
      case 'markets':
        if(claims.some(x=> x == "mk_all")){
          return true;
        }
        break;
    }
    return claims.some(x=> x == categoryName);
  }

  getFilterSettings = (kind: string): FilterSettings => {
    switch (kind.toLowerCase()) {
      case 'business':
        return {
          categories: [
            [{ category: 180, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_bl'), name: 'Бизнес-лента' },
              { category: 153, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_markets'), name: 'Рынки' },
              { category: 170, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_food'), name: 'Продовольствие' },
              { category: 108, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_insurance'), name: 'Страхование' }],
            [{ category: 151, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_economy'), name: 'Экономика' },
              { category: 4, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_energy'), name: 'Энергетика' },
              { category: 19, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_metal'), name: 'Металлургия' }],
            [{ category: 107, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_banks'), name: 'Банки' },
              { category: 160, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_trans'), name: 'Транспорт' },
              { category: 79, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_gold'), name: 'Золото' }],
            [{ category: 12, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'bl_disclosure'), name: 'Раскрытие информации' }
            ]],
          domain: 'RBL'
        };
      case 'markets':
        return {
          categories: [
            [{ category: 3, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'mk_analytics'), name: 'Обзоры и аналитика' },
              { category: 4, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'mk_rumors'), name: 'Говорят на рынке' }],
            [{ category: 5, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'mk_politics'), name: 'Макроэкономика и политика' },
              { category: 6, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'mk_events'), name: 'Календарь событий' }],
            [{ category: 11, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'mk_prices'), name: 'Котировки и цены' }],
            []],
          domain: 'DJF'
        };
        /*
      case 'forex':
        return {
          categories: [
            [{ category: 3, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'fx_analytics_prime'), name: 'Обзоры и аналитика' },
              { category: 4, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'fx_rumors_prime'), name: 'Говорят на рынке' }],
            [{ category: 5, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'fx_politics_prime'), name: 'Макроэкономика и политика' },
              { category: 6, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'fx_events_prime'), name: 'Календарь событий' }],
            [{ category: 11, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'fx_prices_prime'), name: 'Котировки и цены' }],
            []],
          domain: 'DJF'
        };
      case 'stocks':
        return {
          categories: [
            [{ category: 31, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'st_analytics_prime'), name: 'Обзоры и аналитика' },
              { category: 32, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'st_rumors_prime'), name: 'Говорят на рынке' }],
            [{ category: 33, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'fx_politics_prime'), name: 'Макроэкономика и политика' },
              { category: 34, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'fx_events_prime'), name: 'Календарь событий' }],
            [{ category: 30, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),''), name: 'Отчеты и новости компаний' },
              { category: 35, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),''), name: 'Ход торгов' }
            ],
            []],
          domain: 'DJS'
        };
      case 'commodities':
        return {
          categories: [
            [{ category: 43, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'cm_analytics_prime'), name: 'Обзоры и аналитика' },
              { category: 40, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'cm_rumors_prime'), name: 'Говорят на рынке' }],
            [{ category: 42, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'cm_politics_prime'), name: 'Макроэкономика и политика' },
              { category: 41, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),'cm_events_prime'), name: 'Календарь событий' }],
            [{ category: 44, checked: true, enabled: this.isCategoryEnabled(kind.toLowerCase(),''), name: 'Котировки и цены' }],
            []],
          domain: 'DJC'
        };

         */
    }
    return {
      categories: [[], [], [], []],
      domain: ''
    };

  }

}
