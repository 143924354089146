import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NewsBody, NewsTitle } from '../main-news/main-news.component';
import { ApiService } from '../../Services/Api/api.service';
import { delay, retryWhen, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.less']
})
export class NewsListComponent implements OnInit, OnDestroy {

  @Output() next: EventEmitter<number> = new EventEmitter<number>();
  @Input() newsList: NewsTitle[] = [];
  @Input() loading: boolean;
  @Input() count: number;
  bodyLoading: boolean = false;
  currentId: number;
  protected unsubscribe$: Subject<any> = new Subject();

  body: NewsBody = { body: '', title: '', id: -1, priority: 0, date: '', isDisclosure: false };

  constructor(private service: ApiService) {
  }

  ngOnInit(): void {

  }

  openNews = (id: number) => {
    this.currentId = id;
    this.bodyLoading = true;
    this.service.getNewsBody(id)
      .pipe(
        retryWhen(errors => errors.pipe(
          tap(x => console.error(x)),
          delay(1000)
        )),
        takeUntil(this.unsubscribe$))
      .subscribe(x => {
        this.body.body = x.body;
        this.body.id = x.id;
        this.body.title = x.title;
        this.body.priority = x.priority;
        this.body.date = x.date;
        this.body.isDisclosure = x.isDisclosure;
        this.bodyLoading = false;
      });
  }

  nextPage = () => this.next.next(this.newsList[this.newsList.length - 1].id);

  getPageSize = () => this.count - this.newsList.length >= 100 ? 100 : this.count - this.newsList.length;

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
