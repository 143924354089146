<div class="filter">
  <div class="filter_column">
    <div class="filter_column_item">
      <mat-checkbox class="filter_column_checkbox" [(ngModel)]="allChecked" (ngModelChange)="checkAll()"> Все новости</mat-checkbox>
    </div>
  </div>

  <div class="filter_column">
    <div class="filter_column_item" *ngFor="let cat of this.categories[0]">
      <mat-checkbox class="filter_column_checkbox" [(ngModel)]="cat.checked" [disabled]="!cat.enabled" (ngModelChange)="check()">{{cat.name}}</mat-checkbox>
    </div>
  </div>

  <div class="filter_column">
    <div class="filter_column_item" *ngFor="let cat of this.categories[1]">
      <mat-checkbox class="filter_column_checkbox" [(ngModel)]="cat.checked" [disabled]="!cat.enabled" (ngModelChange)="check()">{{cat.name}}</mat-checkbox>
    </div>
  </div>

  <div class="filter_column">
    <div class="filter_column_item" *ngFor="let cat of this.categories[2]">
      <mat-checkbox class="filter_column_checkbox" [(ngModel)]="cat.checked" [disabled]="!cat.enabled" (ngModelChange)="check()">{{cat.name}}</mat-checkbox>
    </div>
  </div>

  <div class="filter_column">
    <div class="filter_column_item" *ngFor="let cat of this.categories[3]">
      <mat-checkbox class="filter_column_checkbox" [(ngModel)]="cat.checked" [disabled]="!cat.enabled" (ngModelChange)="check()">{{cat.name}}</mat-checkbox>
    </div>
  </div>

</div>
