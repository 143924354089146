<div class="calendar">
  <div class="calendar_label">Период:</div>
  <div class="calendar_input">
    <span class="calendar_input_text">{{label}}</span>
    <mat-icon class="calendar_input_icon" (click)="toggle()">date_range</mat-icon>
  </div>
  <div class="calendar_modal" *ngIf="visible">
    <ngx-daterangepicker-material
      (choosedDate)="choosedDate($event)"
      (cancelClicked)="toggle()"
      [showCancel]="true"
      [ranges]="ranges"
      [locale]="locale"
      alwaysShowCalendars="true">
    </ngx-daterangepicker-material>
  </div>
</div>
