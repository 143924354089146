import { Component, OnInit } from '@angular/core';
import { AuthConfig, OAuthErrorEvent, OAuthService, ReceivedTokens } from 'angular-oauth2-oidc';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

const authConfig: AuthConfig = environment.auth;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  title = 'commerce-web';
  userName: string;
  private receivedTokens: ReceivedTokens;

  constructor(private oauthService: OAuthService, private route: ActivatedRoute, private router: Router) {
    if (!environment.production) {
      this.oauthService.events.subscribe(e => (e instanceof OAuthErrorEvent) ? console.error(e) : console.warn(e));
    }
  }

  ngOnInit() {
    if (this.oauthService.hasValidIdToken()) {
      this.initUser();
    } else {
      //this.oauthService.initCodeFlow();
    }
  }

  logout = () => {
    if (this.oauthService.hasValidIdToken()) {
      this.oauthService.logOut(false);
    }
  };

  initUser = () => {
    let claims = this.oauthService.getIdentityClaims();
    if (claims) {
      this.userName = claims['name'];
    } else {
      this.userName = '';
    }
  }
}
