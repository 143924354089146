import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { ApiService } from '../../Services/Api/api.service';
import { NewsBody } from '../main-news/main-news.component';
import { ViewEncapsulation } from '@angular/core'
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-news-body',
  templateUrl: './news-body.component.html',
  styleUrls: ['./news-body.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class NewsBodyComponent implements OnInit {

  @Input() body: NewsBody = {id:0, body: '', date: '', title :'', priority:0, isDisclosure:false};
  @Input() loading: boolean;
  fixed: boolean = false;
  height: string;

  constructor(private oauthService: OAuthService) {
  }

  ngOnInit(): void {
    this.height = this.getHeight();
  }

  ngOnChanges(changes) {
    this.fixed = window.scrollY > 400 && this.body?.body?.length > 0;
    this.height = this.getHeight();
    //console.log('ngOnChanges', changes);
  }

  @HostListener('window:scroll', ['$event']) onScroll($event: Event): void {
    if ($event) {
      this.fixed = window.scrollY > 400 && this.body?.body?.length > 0;
    }
    this.height = this.getHeight();
  }

  @HostListener('window:resize', ['$event']) onResize($event: Event): void {
    if ($event) {
      this.fixed = window.scrollY > 400;
    }
    this.height = this.getHeight();
  }

  getHeight = (): string => {
    if (this.body?.body) {
      if (window.scrollY < 400) {
        return (window.innerHeight - 50 - (400 - window.scrollY)) + 'px';
      } else {
        let isBottom = (document.body.scrollHeight - window.innerHeight - window.scrollY - 178) < 0;
        if (isBottom) {
          return (window.innerHeight - 50) + (document.body.scrollHeight - window.innerHeight - window.scrollY - 178) + 'px';
        } else {
          return (window.innerHeight - 50) + 'px';
        }
      }
    }
    return '0px';
  };
  isImportant = () => this.body.priority == 2 || this.body.title.indexOf('*') == 0;
  close = () => {
    this.body.body = '';
    this.height = '0px';
  }

  isMobile = () => window.innerWidth<600;

  canEdit = ():boolean => this.hasRole('news_editor');

  canDelete = ():boolean => this.hasRole('news_remover');

  hasRole = (role:string):boolean => {
    // @ts-ignore
    let roles = this.oauthService.getIdentityClaims().role;
    if(!roles){
      return false;
    }
    if(!Array.isArray(roles)){
      roles = [roles];
    }
    return roles.some(x=> x == role);
  }
}
