import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-news-text',
  templateUrl: './news-text.component.html',
  styleUrls: ['./news-text.component.less'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class NewsTextComponent implements OnInit {

  constructor() {
  }

  @Input() text: string;
  @Input() isDisclosure: boolean;
  @Input() date: string;
  @Input() isImportant: boolean;

  ngOnInit(): void {
  }

}
