import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { LocalStorageService } from '../../Services/LocalStorageService/local-storage.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRange } from '../calendar/calendar.component';

export interface Category {
  name: string,
  enabled: boolean,
  checked: boolean,
  category: number
}

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CategoryFilterComponent),
      multi: true
    }
  ]
})
export class CategoryFilterComponent implements OnInit, ControlValueAccessor {

  @Input() categories: [Category[], Category[], Category[], Category[]] = [[],[],[],[]];
  @Input() domain: string;

  allChecked: boolean = false;
  categoriesCount: number = 0;
  public value: number[];
  private onChange = (value: any) => {
  };
  private onTouched = () => {
  };

  constructor(private service: LocalStorageService) {
  }

  ngOnInit(): void {
    if(this.categories) {
      this.categoriesCount = this.categories[0].filter(x => x.enabled).length + this.categories[1].filter(x => x.enabled).length + this.categories[2].filter(x => x.enabled).length + this.categories[3].filter(x => x.enabled).length;
      let checkedCount = this.categories[0].filter(x => x.checked).length + this.categories[1].filter(x => x.checked).length + this.categories[2].filter(x => x.checked).length + this.categories[3].filter(x => x.checked).length;
      this.allChecked = checkedCount === this.categoriesCount;
      let categoryFilter = this.service.getCategories(this.domain);
      for (let i = 0; i < this.categories.length; i++) {
        for (let j = 0; j < this.categories[i].length; j++) {
          this.categories[i][j].checked = categoryFilter.some(x => x == this.categories[i][j].category) && this.categories[i][j].enabled;
        }
      }
      this.check();
    }
  }

  ngAfterContentInit(): void {

  }

  check = () => {
    let res: number[] = [];
    res = this.categories[0].filter(x => x.checked).map(x => x.category);
    res = res.concat(this.categories[1].filter(x => x.checked).map(x => x.category));
    res = res.concat(this.categories[2].filter(x => x.checked).map(x => x.category));
    res = res.concat(this.categories[3].filter(x => x.checked).map(x => x.category));
    this.allChecked = res.length === this.categoriesCount;
    this.service.addCategories(this.domain, res);
    this.writeValue(res);
  };
  checkAll = () => {
    if (this.allChecked) {
      for (let i = 0; i < this.categories.length; i++) {
        for (let j = 0; j < this.categories[i].length; j++) {
          if (this.categories[i][j].enabled) {
            this.categories[i][j].checked = true;
          }
        }
      }
    } else {
      for (let i = 0; i < this.categories.length; i++) {
        for (let j = 0; j < this.categories[i].length; j++) {
          if (this.categories[i][j].enabled) {
            this.categories[i][j].checked = false;
          }
        }
      }
    }
    this.check();
  };

  registerOnChange = (fn: any): void => this.onChange = fn;

  registerOnTouched = (fn: any): void => this.onTouched = fn;

  writeValue(obj: number[]): void {
    this.value = obj;
    this.onChange(obj);
    this.onTouched();
  }

}
