import { Component, OnInit } from '@angular/core';
import { NewsBody } from '../main-news/main-news.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../Services/Api/api.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.less']
})
export class EditPageComponent implements OnInit {

  news: NewsBody = { body: '', id: 0, date: '', isDisclosure: false, title: '', priority: 0 };
  loading: boolean = true;

  constructor(private activateRoute: ActivatedRoute, private api: ApiService, private location: Location) {
  }

  ngOnInit(): void {
    let id = this.activateRoute.snapshot.params['id'];
    if (id) {
      this.api.getNewsBody(id).subscribe(x => {
        this.news.body = x.body;
        this.news.id = x.id;
        this.news.title = x.title;
        this.news.priority = x.priority;
        this.news.date = x.date;
        this.news.isDisclosure = x.isDisclosure;
        this.loading = false;
      }, error => this.loading = false);
    }
  }

  goBack = () => this.location.back();

  updateNews = () => {
    this.loading = true;
    this.api.updateNews(this.news.id, this.news.body)
      .subscribe(x => {
          this.loading = false;
          this.goBack();
        }
      );
  }

}
