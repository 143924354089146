<div class="main">
  <div class="main_container">
    <a class="main_container_image" routerLink="/business">
      <img src="assets/img/prime.png" alt="Прайм"/>
    </a>
    <div class="main_container_menu">
      <div class="main_container_menu_left">
        <a href="http://www.1prime.ru/docs/premium/">Продукты и услуги</a>
        <a href="http://shop.1prime.ru">Магазин</a>
        <a href="http://www.1prime.ru/docs/about/">Об агентстве</a>
        <a href="http://www.1prime.ru/docs/advertisement/">Реклама</a>
        <a href="http://www.1prime.biz">English</a>
      </div>
      <div class="main_container_menu_right">
        <a href="/" *ngIf="userName">{{userName}}</a>
        <a href="#" *ngIf="userName" (click)="logout(); $event.preventDefault();">Выход</a>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>

