import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { LocalStorageService } from '../Services/LocalStorageService/local-storage.service';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private lss: LocalStorageService, private oauthService: OAuthService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.oauthService.hasValidIdToken()) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${this.oauthService.getIdToken()}`)
      });
      return next.handle(cloned);
    }
    return next.handle(req);
  }
}
