<div class="news-list">
  <div class="news-list_list">
    <app-progress [loading]="loading"></app-progress>
    <div *ngIf="count" class="news-list_list_count">
      Найдено: {{count}}
    </div>
    <app-news-title [showDate]="item.showDate" [viewed]="item.viewed" [selected]="item.id == currentId" [pushed]="item.pushed" *ngFor="let item of newsList; index as i" [news]="item" [odd]="i % 2 !== 0"
                    (newsClicked)="openNews($event)"></app-news-title>
    <div class="news-list_list_next" *ngIf="newsList.length > 0 && count > newsList.length">
      <button mat-button color="Basic" (click)="nextPage()">Загрузить еще {{getPageSize()}}</button>
    </div>
  </div>
  <div class="news-list_body">
    <app-news-body [body]="body" [loading]="bodyLoading"></app-news-body>
  </div>
</div>
