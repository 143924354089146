import { Component, OnInit } from '@angular/core';
import { AuthConfig, OAuthService, ReceivedTokens } from 'angular-oauth2-oidc';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

const authConfig: AuthConfig = environment.auth

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  private receivedTokens: ReceivedTokens;
  userName: string;

  constructor(private oauthService: OAuthService, private route: ActivatedRoute, private router: Router) {


  }


  ngOnInit(): void {
    this.oauthService.tryLogin({
      onTokenReceived: context => {
        this.receivedTokens = context;
      }
    }).then(async x => {
      if (!this.oauthService.hasValidIdToken()) {
        this.route.queryParams.subscribe(params => {
          sessionStorage.setItem('returnUrl', params['returnUrl'] || '/');
          this.oauthService.tryLogin();
        });
      } else {
        await this.oauthService.loadDiscoveryDocument();
        const returnUrl = sessionStorage.getItem('returnUrl') || '/';
        if (returnUrl) {
          sessionStorage.setItem('returnUrl', null);
          window.location.href = returnUrl;
        }
      }
    }).catch(x => {
      this.router.navigateByUrl('/');
    });
  }

}
