import { Injectable } from '@angular/core';
import * as moment from 'moment'


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  removePushed = (id: number): void => this.removeFromArray(id, 'p');

  addPushed = (id: number): void => this.addToArray(id, 'p');

  getPushed = () => this.getArray('p');

  addViewed = (id: number): void => this.addToArray(id, 'w');

  getViewed = () => this.getArray('w');

  // сохраняем категории фильтра
  addCategories = (key: string, categories: number[]): void => this.addObject(key, categories);
  // получаем категории фильтра
  getCategories = (key: string): number[] => {
    let cat = this.getObject(key);
    if (cat) {
      return cat as number[];
    } else {
      return [];
    }
  }
  // добавить объект
  public addObject = (key: string, item: object): void => localStorage.setItem(key, JSON.stringify(item));
  // получить объект
  getObject = (key: string): any => JSON.parse(localStorage.getItem(key));

  private addToArray = (id: number, key: string): void => {
    let day = moment().day();
    let arr = this.getObject(key);
    if (!arr) {
      arr = [];
    }
    if (!arr.some(x => x.id == id)) {
      arr.push({ id, day });
    }
    this.addObject(key, arr.filter(x => x.day == day));
  }
  private getArray = (key: string): number[] => {
    let day = moment().day();
    let arr = this.getObject(key);
    if (!arr) {
      arr = [];
    }
    return arr.filter(x => x.day == day).map(y => y.id);
  }
  removeFromArray = (id: number, key: string): void => {
    let arr = this.getObject(key);
    if (!arr) {
      return;
    }
    this.addObject(key, arr.filter(x => x.id != id));
  }
}
